<template lang="pug">
#page
  .header
    .logo
      img(src="@/assets/landPage/logo.png")
    .download-href
      //- div(@click="downloadAppFun") DOWNLOAD
      div(@click="linkTo('https://twitter.com/VegasVerz')")
        img(src="@/assets/landPage/twitter.png")
        span Twitter
      div(@click="linkTo('https://instagram.com/vegasverz')")
        img(src="@/assets/landPage/instagram.png")
        span Instagram
  .item1
    img.gif(src="@/assets/landPage/video_part.gif")
    .container
      .title Your Door Into The Exciting Metaverse Of Las Vegas
      .desc
        div Here, you can freely roam between the physical world and the exciting virtual world of VegasVerz.
        //- div Check in, unlock your room, book a table and more.
      .btn
        .btn-item(@click="linkTo('https://apps.apple.com/us/app/vegasverz/id1616278835')")
          img(src="@/assets/landPage/apple.png")
          .right
            .right-line1 Download on the
            .right-line2 App Store
        .btn-item.btn1(@click="linkTo('https://play.google.com/store/apps/details?id=com.bytepace.vegasverse')")
          img(src="@/assets/landPage/google.png")
          .right
            .right-line1 GET IT ON
            .right-line2 Google Play
      .video-btn
        div(@click="dialogShow = true")
          img(src="@/assets/landPage/play.png")
          span WATCH THE VIDEO
  .item
    .img
      img(src="@/assets/landPage/back_1.png")
    .desc
      .title Attend The Hottest Events In Las Vegas
      .detail Immerse yourself into the extraordinary adventures of our universe as you attend the hottest events in Vegas, collect limited edition NFTs, make lifelong friends and even inhabit the roles of characters in your own sensational storyline!
  .item.item-even
    .img
      img(src="@/assets/landPage/back_2.png")
    .desc
      .title Play To Earn In VegasVerz
      .detail Here in VegasVerz, the gameplay is simple, but the world is limitless. And it’s all connected to the real world we live in right now.
  .item
    .img
      img(src="@/assets/landPage/back_3.png")
    .desc
      .title Unlocking Special Coupons For Special Prizes
      .detail Here, you can freely roam between the physical world and the exciting virtual world of VegasVerz. The players obtain NFT coupons which can link together game players with offline businesses, unlocking special coupons for special prizes, discounts and more!
  .item.item-even
    .img
      img(src="@/assets/landPage/back_4.png")
    .desc
      .title Support Your Favorite Stars In VegasVerz
      //- .detail Collect limited edition NFTs, make lifelong friends and even inhabit the roles of characters in your own sensational storyline!
      .detail  Track all the important and popular events that matter to YOU. Participate in your favorite star’s metaverse stories and be their biggest fan by supporting them in the metaverse.
  .item
    .img
      img(src="@/assets/landPage/back_5.png")
    .desc
      .title Collect Limited Edition NFTs
      .detail  Obtain and collect rare NFT’s of the stars you love and even share it on your favorite social networks! Jump into your star’s world by becoming an important figure in their life in one of our many gameplay storylines!
  .secend-bottom
    .title Remark Holdings, Inc.
    .bottom-item
      div 800 S. Commerce st.
      div Las Vegas, NV 89106
    .emial vegasverz@remarkholdings.com
  .page-bottom
    div © 2022 Remark Holdings. All Rights Reserved.
  .dialog-video(v-if="dialogShow" @click="dialogShow = false")
    .video-box(@click.stop="clickStop")
      .close.el-icon-circle-close(@click.stop="dialogClose")
      video(controls autoplay loop)
        source(src="https://vegaverse-prd.s3.amazonaws.com/vegasverz_instruction.mp4")
</template>

<script>
import { ref } from 'vue'
export default{
  name: 'LandPage',
  setup() {
    let dialogShow = ref(false)

    const linkTo = (url) => {
      window.open(url)
    }

    return {
      dialogShow,
      linkTo
    }
  }
}
// export default {
//   data() {
//     return {
//       dialogShow: false
//     }
//   },
//   created() {

//   },
//   mounted() {
//   },
//   methods: {
//     linkTo(url) {
//       window.open(url)
//     },
//     clickStop() {
//       console.log('clickStop')
//     },
//     dialogClose() {
//       this.dialogShow = false
//     },
//     downloadAppFun() {
//     }
//   }
// }
</script>

<style lang="scss" scoped>
// 自适应封装
$vm_base: 1920; // UI尺寸

@function vw($px) {
  @return ($px / $vm_base) * 100vw
};

$page_color: #5B00CF;
#page{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.dialog-video{
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .5);

  .video-box{
    width: 80%;
    padding: 4px;
    padding-bottom: 0;
    background: white;
    position: relative;
    video{
      width: 100%;
    }
  }
}

// PC端样式
@media screen and (min-width: 750px) {
  .close{
    font-size: vw(80);
    position: absolute;
    right: vw(30);
    top: vw(30);
    color: white;
    cursor: pointer;
    z-index: 3;
  }
  .header{
    height: 50px;
    display: flex;
    align-items: center;
    background: $page_color;
    >div{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo{
      font-weight: 800;
      color: white;
      img{
        width: 180px;
      }
    }
    .download-href{
      color: white;
      display: flex;
      align-items: center;
      div{
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
          width: 30px;
          margin-right: 4px;
        }
        &:nth-child(1){
          margin-right: 30px;
        }
      }
      // div{
      //   background: white;
      //   height: 50px;
      //   line-height: 50px;
      //   padding: 0 20px;
      //   font-weight: 600;
      //   font-size: 20px;
      //   color: $page_color;
      //   font-family: Poppins-Bold, Poppins;
      // }
    }
  }
  .item1{
    width: 100%;
    height: 100%;
    position: relative;
    .gif{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .container{
      position: absolute;
      background: rgba(0, 0, 0, .6);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div{
        text-align: center;
      }
      .title{
        color: white;
        font-size: 50px;
        max-width: 700px;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
      }
      .desc{
        color: #CCCCCC;
        margin: 20px 0;
      }
      .btn{
        display: flex;
        div{
          text-align: left;
        }
        .btn-item{
          cursor: pointer;
          border: 1px solid rgba(255, 255, 255, .2);
          background: rgba(0, 0, 0, .44);
          padding: 8px 16px;
          display: flex;
          align-items: center;
          img{
            width: 30px;
            margin-right: 8px;
          }
          .right-line1{
            color: #707070;
            font-size: 12px
          }
          .right-line2{
            color: white
          }
        }
        .btn1{
          margin-left: 20px
        }
      }
    }
    .video-btn{
      position: absolute;
      bottom: 100px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      div{
        cursor: pointer;
        background: rgba(255, 255, 255, .22);
        height: 50px;
        padding: 0 30px;
        border-radius: 30px;
        color: rgba(255, 255, 255, .6);
        display: flex;
        align-items: center;
        img{
          width: 36px;
          margin-right: 8px;
        }
      }
    }
  }
  .item{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $page_color;
    background-image: url('../assets/landPage/back_max_1.png');
    .img{
      width: vw(300);
      // height: vw(600);
      img{
        width: 100%;
      }
    }
    .desc{
      margin-left: vw(150);
      max-width: 720px;
      .title{
        margin-bottom: 30px;
        font-size: 58px;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        color: white;
        // font-weight: bold;
      }
      .detail{
        font-size: 26px;
        line-height: 36px;
        color: rgba(255, 255, 255, .68);
        font-weight: 300;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
      }
    }
  }
  .item-even{
    background: #f4f4f4;
    flex-direction: row-reverse;
    .desc{
      margin-left: 0;
      margin-right: vw(150);
      .title{
        color: #010101;
      }
      .detail{
        color: #010101;
      }
    }
  }
  .secend-bottom{
    background: rgba(0, 0, 0, .9);
    color: white;
    text-align: center;
    padding: vw(50) 0;
    .title{
      font-size: vw(36)
    }
    .bottom-item{
      margin: vw(30) 0;
      line-height: vw(36);
    }
  }
  .page-bottom{
    height: 80px;
    line-height: 80px;
    background: black;
    color: white;
    text-align: center;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  }
}

// 移动端样式
$vwm_base: 750;
@function vwm($px) {
  @return ($px / $vwm_base) * 100vw
};
@media screen and (max-width: 750px) {
  .close{
    font-size: vwm(60);
    position: absolute;
    right: vwm(30);
    top: vwm(30);
    color: white;
    cursor: pointer;
    z-index: 3;
  }
  .header{
    height: vwm(100);
    display: flex;
    align-items: center;
    background: $page_color;
    >div{
      display: flex;
      align-items: center;
    }
    .logo{
      flex: 1;
      font-weight: 800;
      color: white;
      img{
        margin-left: vwm(30);
        width: vwm(300)
      }
    }
    .download-href{
      color: white;
      display: flex;
      align-items: center;
      padding-right: vwm(30);
      div{
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
          width: vwm(60);
        }
        span{
          display: none;
        }
        &:nth-child(1){
          margin-right: vwm(16);
        }
      }
      // div{
      //   background: white;
      //   height: vwm(100);
      //   line-height: vwm(100);
      //   padding: 0 vwm(28);
      //   font-weight: 600;
      //   color: $page_color;
      // }
    }
  }
  .item1{
    width: 100%;
    height: vwm(760);
    position: relative;
    background: rgba(0, 0, 0, .8);
    display: flex;
    align-items: center;
    .gif{
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
    .container{
      position: absolute;
      background: rgba(0, 0, 0, .6);
      top: 0;
      height: vwm(760);
      left: 0;
      width: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div{
        text-align: center;
      }
      .title{
        color: white;
        font-size: vwm(40);
        width: 80%;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        font-weight: 300;
      }
      .desc{
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        color: #ccc;
        margin: vwm(10) 0;
        width: 80%;
        font-size: vwm(30)
      }
      .btn{
        display: flex;
        div{
          text-align: left;
        }
        .btn-item{
          border: 1px solid rgba(255, 255, 255, .2);
          background: rgba(0, 0, 0, .1);
          padding: vwm(10) vwm(26);
          display: flex;
          align-items: center;
          img{
            width: vwm(46);
            margin-right: vwm(18);
          }
          .right-line1{
            font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
            color: #ccc;
            font-size: vwm(12);
          }
          .right-line2{
            color: white;
            font-size: vwm(28);
          }
        }
        .btn1{
          margin-left: 20px
        }
      }
      .video-btn{
        margin-top: vwm(180);
        display: flex;
        justify-content: center;
        div{
          cursor: pointer;
          background: rgba(255, 255, 255, .22);
          height: vwm(80);
          line-height: vwm(80);
          padding: 0 vwm(30);
          border-radius: vwm(80);
          color: #ccc;
          display: flex;
          align-items: center;
          img{
            width: vwm(50);
            margin-right: vwm(18);
          }
        }
      }
    }
  }
  .item{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background: $page_color;
    background-image: url('../assets/landPage/back_max_1.png');
    .img{
      width: vwm(300);
      img{
        width: 100%;
      }
    }
    .desc{
      max-width: 76%;
      margin: 0 auto;
      .title{
        margin: vwm(30) 0;
        font-size: vwm(46);
        color: white;
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        // font-weight: bold;
        text-align: center;
      }
      .detail{
        font-size: vwm(30);
        color: rgba(255, 255, 255, .68);
        font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
        text-align: center;
      }
    }
  }
  .item-even{
    background: #f4f4f4;
    // flex-direction: column-reverse;
    .desc{
      .title{
        color: #010101;
        // margin: 0;
      }
      .detail{
        color: #010101;
        // margin: vwm(30) 0;
      }
    }
  }
  .secend-bottom{
    background: rgba(0, 0, 0, .9);
    color: white;
    text-align: center;
    padding: vwm(50) 0;
    font-size: vwm(22);
    .title{
      font-size: vwm(30)
    }
    .bottom-item{
      margin: vwm(30) 0;
      line-height: vwm(36);
    }
  }
  .page-bottom{
    height: vwm(100);
    // line-height: vwm(120);
    background: black;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: vwm(22);
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  }

}

</style>
